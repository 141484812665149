import "core-js/stable";
import "regenerator-runtime/runtime";
// import Cookies from "js-cookie";

import barba from "@barba/core";
import PropertySlider from "./property-slider";
import MortgageCalc from "./mortgage-calc";
import TeamFilter from "./team-filter";
import GoogleMap from "./google-maps";
import PropFilter from "./prop-filter";
import CardSlider from "./card-slider";
import PropListing from "./prop-listing";
import Form from "./utils/form";
import eventBus from "./utils/eventBus";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

// import "./utils/lazyload";

// barba.init({
//   // TODO: Add transition classes`
//   debug: false,
//   timeout: 6000,
//   preventRunning: true,
// });

Vue.options.delimiters = ["@{", "}"];

barba.hooks.beforeEnter((data) => {
  console.log("page:before");
  eventBus.emit("page:before");
});

barba.hooks.after((data) => {
  console.log("page:after");
  window.scroll(0, 0);
  All();
});

All();

function All() {
  Vue.use(VueLazyload, {
    // set observer to true
    observer: true,
    listenEvents: [
      "scroll",
      "wheel",
      "mousewheel",
      "resize",
      "animationend",
      "transitionend",
      "touchmove",
      "touchcancel",
      "touchend",
      "touchenter",
      "touchstart",
    ],
    // optional
    observerOptions: {
      rootMargin: "0px",
      threshold: 0.1,
    },
  });

  Vue.directive("swipe", {
    inserted(el) {
      el.classList.add("v-swipe");
      ScrollTrigger.create({
        // markers: true,
        trigger: el,
        start: "top bottom+=10%",
        onEnter: () => {
          el.classList.add("in");
        },
        // onToggle: (self) => {
        // },
      });
    },
  });

  Vue.directive("fadein", {
    bind(el) {
      let tl = gsap
        .timeline({
          scrollTrigger: {
            // markers: true,
            trigger: el,
            start: "top bottom-=20%", // trigger viewport
            end: "bottom top", // trigger viewport
            toggleActions: "restart reverse play reverse",
          },
        })
        .fromTo(
          el,
          0.5,
          {
            opacity: 0,
          },
          {
            opacity: 1,
          }
        );
    },
  });

  eventBus.on("update-props", () => {
    ScrollTrigger.refresh();
  });

  function action(attr, func) {
    if (attr.indexOf(".") !== -1 || attr.indexOf("#") !== -1) {
      document.querySelectorAll(attr).forEach((el) => func(el));
    } else {
      document
        .querySelectorAll(`[data-action="${attr}"]`)
        .forEach((el) => func(el));
    }
  }

  Vue.component("mortgage-calc", MortgageCalc);

  Vue.component("team-filter", TeamFilter);

  Vue.component("card-slider", CardSlider);

  Vue.component("prop-listing", PropListing);

  Vue.component("prop-filter", PropFilter);

  Vue.component("spinner", {
    template:
      '<div class="uk-text-center uk-padding"><span class="purple" uk-spinner="ratio: 3"></span><div>',
  });

  Vue.component("google-map", GoogleMap);

  var app = new Vue({
    el: "#app",
    components: {
      Multiselect: window.VueMultiselect.default,
    },
    computed: {
      mapHeight: function () {
        if (this.showMap) {
          return window.innerHeight - (window.innerWidth > 700 ? 15 : 0) + "px";
        } else {
          return "auto";
        }
      },
    },
    data: {
      url: window.siteUrl,
      showMap: false,
      menuOpen: false,
    },
    methods: {
      test: function () {
        console.log("open ghallery");
        initGallery();
      },
      money: function (val) {
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },

      openMeasure: function () {
        UIkit.modal("#measure").show();
      },
    },
    mounted() {
      eventBus.on("page:before", () => {
        this.$destroy();
      });
    },
  });

  if (window.location.href.indexOf("arrangesuccess") !== -1) {
    UIkit.notification({
      message:
        '<span uk-icon="icon: check"></span> Thank you for your message a member of staff will get back to you shortly',
      timeout: 10000,
      // status:'primary'
    });
  }

  Array.prototype.clean = function (deleteValue) {
    for (var i = 0; i < this.length; i++) {
      if (this[i] == deleteValue) {
        this.splice(i, 1);
        i--;
      }
    }
    return this;
  };

  function initGallery() {
    var pswpElement = document.querySelectorAll(".pswp")[0];

    // build items array
    // var items = window.propImgs;
    var items = propertyImages;
    console.log("items");
    console.log(items);
    // define options (if needed)
    var options = {
      // optionName: 'option value'
      // for example:
      index: 0, // start at first slide
    };

    // Initializes and opens PhotoSwipe
    var gallery = new PhotoSwipe(
      pswpElement,
      PhotoSwipeUI_Default,
      items,
      options
    );
    gallery.init();
  }

  // window.addEventListener("load", () => {
  new Swiper(".testimonial-container", {
    // Optional parameters
    loop: true,
    autoplay: {
      delay: 5000,
    },
  });

  action("PropertySlider", PropertySlider);
  action("Form", Form);

  if (document.querySelector(".insta")) {
    const instaSlide = new Swiper(".insta", {
      // Optional parameters
      slidesPerView: 3,
      loop: true,
      spaceBetween: 15,
      autoplay: {
        delay: 5000,
      },
      breakpoints: {
        700: {
          slidesPerView: 2,
        },
        380: {
          slidesPerView: 1,
        },
      },
    });

    document.querySelector(".insta-left").addEventListener("click", () => {
      instaSlide.slidePrev();
    });
    document.querySelector(".insta-right").addEventListener("click", () => {
      instaSlide.slideNext();
    });
  }
  // });
}

window.addEventListener("load", () => {
  const script = document.createElement("script");
  script.src = window.siteUrl + "build/js/uikit-icons.min.js";
  document.body.append(script);
});

// document.querySelector(".shutter").classList.remove("closed");

// window.addEventListener("beforeunload", (e) => {
//   console.log("beforeunload");
//   document.querySelector(".shutter").classList.add("closed");
// });
